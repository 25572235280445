import { graphql, useStaticQuery } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';
import React from 'react';
import { BuyingNewQuery } from '../../../../../graphql-types';
import Avatar from '../../../avatar';
import FeatureCard from '../../../feature-cards/feature-card';
import FeatureSection from '../../../feature-section';
import { FeatureSectionProps } from '../../../feature-section/model';
import ParallaxBackground from '../../../parallax-background';
import * as style from './index.module.scss';

export default function BuyingNew(props: { buyingRate: number }) {
  const data: FeatureSectionProps = {
    title: 'Buying a new home?',
    fullLengthTitle: true,
    items: [
      {
        align: 'right',
        title: 'Looking for a new place to call home?',
        subtitle: 'Buy with Confidence',
        description: `Search for a new home, get connected with a local agent, and shop for your next mortgage with Nuborrow.`,
        actions: [
          { label: `Find my new Home`, href: '/buy', isPrimary: true },
          { label: `Apply for free`, href: 'https://apply.nuborrow.com' }
        ],
        art: buyArt(props.buyingRate)
      }
    ]
  };

  return <FeatureSection {...data} />;
}

const buyArt = (rate: number) => {
  const avatarsAndBGImg = useStaticQuery<BuyingNewQuery>(dataQuery);

  return (
    <div className="d-flex flex-column align-items-center">
      <div className={style.parallaxWrapper}>
        <div className={style.sideCaption}>
          2,000 plus
          <br />5 stars reviews
        </div>
        <ParallaxBackground
          className={style.heroImage}
          image={avatarsAndBGImg.file as unknown as ImageDataLike}
        />
        <div className={style.homeOwners}>
          <div className={''}>
            {avatarsAndBGImg.allFile.nodes.map((avatarFile) => (
              <Avatar
                className={style.avatar}
                key={avatarFile.relativePath}
                file={avatarFile as unknown as ImageDataLike}
                alt={avatarFile.relativePath}
              />
            ))}
          </div>
          <div className={style.subtitle}>
            <b>4,361</b> home buyers applied last month.
          </div>
        </div>
        <FeatureCard
          className={style.buyCard}
          width={270}
          height={350}
          href="/buy"
          title="Purchase."
          caption="Today's Rate"
          value={rate}
          description="Buying my Home"
          buttonLabel="Buy my Home"
        />
      </div>
    </div>
  );
};

const dataQuery = graphql`
  query BuyingNew {
    allFile(limit: 7, filter: { relativePath: { glob: "person-*.jpg" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, aspectRatio: 1, width: 40)
        }
        relativePath
      }
    }
    file(relativePath: { glob: "photos/buying_new.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, aspectRatio: 0.75, width: 1000)
      }
      relativePath
    }
  }
`;
